<template>
   <ObiText
      v-bind="attrs"
      v-on="$listeners"
      class="obi-button-group"
      :class="{ 'btn-group': !vertical, 'btn-group-vertical': vertical }"
   >
      <slot />
   </ObiText>
</template>

<script>
import { keys, omit } from 'lodash';

export default {
   name: 'ObiButtonGroup',

   props: {
      vertical: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      attrs() {
         return omit(this.$attrs, keys(this.$props));
      },
   },
};
</script>

<style lang="scss" scoped>
.obi-button-group {
}
</style>
